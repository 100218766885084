@font-face
  font-family: 'Montserrat'
  font-display: swap
  src: url('../assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format("truetype-variations")

@font-face
  font-family: 'ClashDisplay-Variable'
  src: url('../assets/fonts/ClashDisplay/fonts/ClashDisplay-Variable.ttf') format('truetype')
  font-weight: 200 700
  font-display: swap
  font-style: normal

@font-face
  font-family: 'ClashDisplay-Extralight'
  src: url('../assets/fonts/ClashDisplay/fonts/ClashDisplay-Extralight.ttf') format('truetype')
  font-weight: 200
  font-display: swap
  font-style: normal

@font-face
  font-family: 'ClashDisplay-Light'
  src: url('../assets/fonts/ClashDisplay/fonts/ClashDisplay-Light.ttf') format('truetype')
  font-weight: 300
  font-display: swap
  font-style: normal

@font-face
  font-family: 'ClashDisplay-Regular'
  src: url('../assets/fonts/ClashDisplay/fonts/ClashDisplay-Regular.ttf') format('truetype')
  font-weight: 400
  font-display: swap
  font-style: normal

@font-face
  font-family: 'ClashDisplay-Medium'
  src: url('../assets/fonts/ClashDisplay/fonts/ClashDisplay-Medium.ttf') format('truetype')
  font-weight: 500
  font-display: swap
  font-style: normal

@font-face
  font-family: 'ClashDisplay-Semibold'
  src: url('../assets/fonts/ClashDisplay/fonts/ClashDisplay-Semibold.ttf') format('truetype')
  font-weight: 600
  font-display: swap
  font-style: normal

@font-face
  font-family: 'ClashDisplay-Bold'
  src: url('../assets/fonts/ClashDisplay/fonts/ClashDisplay-Bold.ttf') format('truetype')
  font-weight: 700
  font-display: swap
  font-style: normal

p, a, strong, em, td, .advert
  font-family: "Montserrat"
  font-weight: 500

h1, h2, h3, h4, h5, h6, span, header
  font-family: "ClashDisplay-Bold"
