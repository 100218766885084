
@import "../sass/variables"
@import "../sass/fonts"
@import "../sass/buttons"

// Media queries
@media (max-width: #{$screen-md-min})
  .bnr-head
    min-height: 100vh

  .media-filter
    margin-bottom: 20px

    li
      margin-bottom: 0

  .padding-top-80
    padding-top: 20px !important

  .padding-bottom-80
    padding-bottom: 20px !important

  .sub-bnr
    min-height: 300px

  .nav
    position: fixed
    top: 0
    left: 0
    z-index:999999

    width: 100%
    height: 100%

    display: flex
    align-items: center
    justify-content: center
    flex-direction: column

    border-radius: 0
    padding: 50px
    background: $color-bg-dark


.row-no-padding
  [class*="col-"]
    padding-left: 0 !important
    padding-right: 0 !important

hr
  border-top: 1px solid $color-primary-darker
  margin: 30px 0

.card
  background-color: $color-bg-card
