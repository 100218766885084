// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px

// Small tablets (portrait view)
$screen-md-min: 768px

// Tablets and small desktops
$screen-lg-min: 992px

// Large tablets and desktops
$screen-xl-min: 1200px

// Color and design variables
$color-primary:  #B862B2
$color-primary-dark: #5E2D73
$color-primary-darker: #261e32
$color-bg-dark: #101010
$color-bg-card: #303030
$color-bg-primary: #B862B2
$color-bg: #ececec
$color-font: white
$color-font-light: #ababab
$color-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px
$color-shadow-dark: rgba(0, 0, 0, 0.35) 0px 5px 15px


$border-radius: 5px
$border-radius-larger: 8px

$padding-internal: 100px
$padding-internal-mobile: 20px
$padding-card: 20px
