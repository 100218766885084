@media (min-width: 1400px) and (max-width: 1920px) {
}

@media (max-width: 1600px) {
  .info-office {
    padding: 0px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .latest-work .col-4 .item.filter-home {
    width: 100%;
  }

  .latest-work .col-4 .item.filter-home li {
    display: inline-block;
    width: auto;
  }

  .latest-work .col-4 .item.filter-home li a {
    padding: 0 10px;
    margin-top: -2px;
  }

  .latest-work .position-center-center {
    width: 100%;
    text-align: center;
  }

  .latest-work .filter {
    min-height: auto;
    height: 80px;
  }

  .fst-up {
    margin-top: 0px;
  }
}

@media (max-width: 1024px) {
  .fst-up {
    margin-top: -100px;
  }

  .sub-space {
    padding-left: 0px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .top-info-con ul li {
    width: 27%;
  }

  .fst-up {
    margin-top: 0px;
  }

  .top-info-con ul li {
    float: left;
  }

  .top-info-con {
    width: 67%;
  }

  header .logo:after {
    right: -100px;
  }

  header .search-nav ul.dropdown {
    width: 100% !important;
  }

  .header-style-2.header-style-3 .logo {
    margin: 20px 0;
    width: 100%;
    text-align: center;
  }

  .header-style-2.header-style-3 nav {
    margin-top: 20px;
  }

  .header-style-2.header-style-3 nav {
    width: 100%;
  }

  .promo-block .promo-text {
    padding-right: 20px;
  }

  .contact.style-2 .contact-right {
    padding-right: 15px;
    padding-left: 15px !important;
  }

  .services {
    padding-left: 0px;
  }

  .latest-work .col-4 .item.filter-home {
    width: 100%;
  }

  .latest-work .col-4 .item.filter-home li {
    display: inline-block;
    width: auto;
  }

  .latest-work .col-4 .item.filter-home li a {
    padding: 0 10px;
    margin-top: -2px;
  }

  .latest-work .position-center-center {
    width: 100%;
    text-align: center;
  }

  .latest-work .filter {
    min-height: auto;
    height: 80px;
  }

  .offer-services li {
    width: 33.333%;
  }

  .offer-services li .position-center-center {
    width: 100%;
  }

  .counter ul li {
    width: 50%;
    margin-bottom: 30px;
  }

  .req-quote .col-md-6.pull-right {
    width: 100%;
  }

  .gallery .col-6 li {
    width: 33.3333%;
  }

  .gallery .col-6 li img {
    width: 100%;
  }

  header .navbar li {
    margin: 0 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .top-right ul li {
    float: left;
    display: inline-block;
    margin-left: 10px;
    padding-right: 10px;
  }

  .top-info-con {
    width: 100%;
    margin-bottom: 20px;
  }

  header {
    margin-top: 0px;
  }

  .offer-services li {
    width: 50%;
  }

  .offer-services li .position-center-center {
    width: 100%;
  }

  .ownmenu ul.dropdown,
  .ownmenu ul.dropdown li ul.dropdown {
    width: 250px !important;
  }

  .ownmenu ul.dropdown,
  .ownmenu ul.dropdown li ul.dropdown {
    padding: 20px;
  }

  header .ownmenu ul.dropdown {
    padding: 0px;
  }

  header nav .ownmenu li {
    float: left;
    margin: 0px 10px;
  }

  .bnr-head .position-center-center {
    width: 100%;
  }

  header .quotes a {
    padding: 0px 15px;
    font-size: 14px;
  }

  W.welcome article {
    margin-bottom: 30px;
  }

  .promos {
    text-align: center;
  }

  .promos .text-right {
    text-align: center;
  }

  .top-info-con ul li {
    float: left;
  }

  .top-info-con ul {
    text-align: left;
  }

  .procsss {
    position: relative;
    margin-top: 0px;
    top: 0px;
  }

  .procsss .container {
    width: 100%;
  }

  .procsss ul {
    padding: 30px 0px;
  }

  .call-out {
    text-align: center;
  }

  .call-out h4 {
    line-height: 40px;
    margin-bottom: 20px;
  }

  .heading {
    width: 100%;
  }

  .what-we-do .rotatebox {
    min-height: auto;
    line-height: normal;
  }

  .services .tab-content {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .emergency {
    text-align: center;
  }

  .emergency .text-right {
    text-align: center;
  }

  .news .post-detail article {
    margin-bottom: 30px;
  }

  .latest-work .col-4 .item.filter-home {
    width: 100%;
  }

  .latest-work .col-4 .item.filter-home li {
    display: inline-block;
    width: auto;
  }

  .latest-work .col-4 .item.filter-home li a {
    padding: 0 10px;
    margin-top: -2px;
  }

  .latest-work .position-center-center {
    width: 100%;
    text-align: center;
  }

  .latest-work .col-4 .item {
    width: 33.3333%;
  }

  .team ul li {
    width: 50%;
    float: left;
    margin-bottom: 30px;
  }

  .clients ul li {
    float: left;
    width: 33.3333%;
    padding: 0px;
  }

  footer h4 {
    display: inline-block;
    width: 100%;
    margin: 50px 0;
    margin-bottom: 20px;
  }

  .footer-works > .col-md-3 {
    float: left;
    width: 33.3333%;
    margin: 0px;
    padding: 10px;
  }

  .latest-work .filter {
    min-height: 80px;
    display: inline-block;
    width: 100%;
  }

  .services .nav-tabs {
    display: inline-block;
    width: 100%;
  }

  .offer-services li {
    width: 33.333%;
  }

  .offer-services li .position-center-center {
    width: 100%;
  }

  header .search-nav ul.dropdown {
    width: 100% !important;
  }

  header .ownmenu .look-book .nav-img {
    width: 70px;
    margin-right: 10px;
  }

  header .ownmenu .look-book .media-heading {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  header .ownmenu li > .megamenu li a {
    padding: 5px 0;
  }

  header .ownmenu .look-book p {
  }

  header .ownmenu > li > .megamenu.full-width {
    padding: 30px;
  }

  header .ownmenu .look-book .media-body a {
    padding: 5px 0 0 0px;
  }

  header .ownmenu > li > .megamenu.full-width .nav-post .col-sm-3 {
    width: 50%;
  }

  header .ownmenu .megamenu h6 {
    margin-top: 20px;
  }

  header .ownmenu ul.dropdown li a {
    letter-spacing: 1px;
  }

  .what-we-do .rotatebox {
    width: 50%;
    float: left;
  }

  .what-we-do .rotatebox:nth-child(3) {
    width: 100%;
  }

  .services {
    padding-left: 0px;
  }

  .team .social li {
    width: auto;
  }

  .media.padding-right-100 {
    padding-right: 0px !important;
  }

  .testi .owl-nav {
    left: 50%;
    bottom: -40px;
  }

  .tab-pane.text-right {
    text-align: center;
  }

  .services .nav-tabs {
    text-align: center;
  }

  .services .nav-tabs li {
    float: none;
    display: inline-block;
    text-align: left;
  }

  .portfolio.style-2 li.item {
    width: 50% !important;
  }

  .counter ul li {
    width: 50%;
    margin-bottom: 30px;
  }

  header .quotes a {
    margin-right: 0px;
  }

  .block-about h4 {
    margin-top: 30px;
  }

  .heading {
    margin-top: 30px;
  }

  .testi-name {
    width: 70%;
  }

  header .logo a {
    margin-top: 0px;
  }

  .latest-work .filter {
    top: 0px;
    position: relative;
    margin-bottom: 20px;
    min-height: auto;
  }

  .members li {
    width: 50%;
  }

  .req-quote .col-md-6.pull-right {
    width: 100%;
  }

  .req-quote form {
    margin-bottom: 50px;
    display: inline-block;
    width: 100%;
  }

  .shipper {
    margin-top: 0px;
  }

  .sub-bnr .position-center-center {
    width: 100%;
  }

  .portfolio-wrapper .items .item {
    width: 50%;
  }

  footer h5 {
    display: inline-block;
    width: 100%;
    margin-top: 30px;
  }

  footer .text-post {
    float: left;
    width: auto;
    margin-left: 20px;
  }

  footer .subcribe {
    margin-bottom: 0px;
  }

  .gallery .col-6 li {
    width: 33.3333%;
  }

  .gallery .col-6 li img {
    width: 100%;
  }

  .team .col-md-3 {
    width: 50%;
    float: left;
    margin-bottom: 30px;
  }

  .foot-info-con li {
    width: 50%;
    float: left;
    margin-bottom: 30px;
  }

  footer h6 {
    display: inline-block;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .bnr-head .position-center-center {
    width: 100%;
  }

  .dark-layout #content {
    background: #282a41;
  }

  .discover-music li {
    width: 50%;
    float: left;
  }

  .discover-style-2 .center-part {
    margin-top: 0px;
  }

  .discover-style-2 .outer {
    width: 100%;
    border: none;
    margin: 0px;
    height: auto;
  }

  .discover-style-2 .discover-music li {
    width: 50% !important;
    min-width: auto !important;
    top: auto !important;
    position: relative !important;
    right: auto !important;
    left: auto !important;
    float: left;
    margin-bottom: 30px !important;
  }

  .discover-style-2 .discover-music {
    margin-top: 30px;
  }

  .discover-style-2 .discover-music li a {
    float: none !important;
    width: auto !important;
    text-align: left;
    display: inline-block;
    margin-bottom: 0px !important;
    margin-top: 26px !important;
    margin-left: 20px;
  }

  .discover-style-2 .discover-music {
    text-align: left;
  }

  .discover-style-2 .discover-music li img {
    float: left;
  }

  .dark-layout .about-inn h4 {
    margin-top: 0px;
  }

  .latest-release .col-md-6 .col-md-6 {
    width: 50%;
    float: left;
  }

  .latest-release .col-md-6 img {
    width: 100%;
  }

  footer h5 {
    display: inline-block;
    width: 100%;
    margin-top: 30px;
  }

  header {
    padding-top: 0 !important;
  }

  header .navbar {
  }

  .services {
    padding-left: 0px;
  }

  .sub-bnr .position-center-center {
    width: 100%;
  }

  .portfolio-wrapper .items .item {
    width: 50%;
  }

  .top-info-con ul li.fst {
    width: 50%;
    float: left;
  }

  .top-info-con ul li {
    width: 50%;
    float: left;
    margin: 0px;
    border: none;
  }

  .top-info-con ul li.lst {
    width: 100%;
  }

  .news .post-detail article {
    margin-bottom: 30px;
  }

  .offer-services li {
    width: 50%;
  }

  .offer-services li .position-center-center {
    width: 100%;
  }

  .tab-pane.text-right {
    text-align: center;
  }

  .media.padding-right-100 {
    padding-right: 0px !important;
  }

  .top-bar {
    margin: 0px;
    padding: 0px;
  }

  .top-right ul {
    text-align: center;
  }

  .top-right ul li .media {
    text-align: left;
  }

  header .navbar li a {
    padding: 0 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .top-right ul li {
    float: none;
  }

  header .quotes {
    width: 100%;
  }

  header .quotes a {
    width: 100%;
    height: 50px;
    line-height: 50px;
  }

  header .navbar li {
    margin: 0px;
  }

  .top-right {
    width: 100%;
  }

  .top-right ul {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    display: inline-block;
  }

  .testi .owl-nav {
    left: 50%;
    bottom: -40px;
  }

  .sub-bnr h4 {
    margin-top: 0px;
  }

  .sub-bnr {
    min-height: 350px;
  }

  footer .text-post {
    float: left;
    width: auto;
    margin-left: 20px;
  }

  .heading span {
    width: 100%;
  }

  #tabs {
    margin-bottom: 30px;
  }

  .ownmenu {
    width: 100%;
  }

  .ownmenu > li.nav-on-of span.icon {
    padding: 0px;
    margin: 0px;
  }

  .top-info-con {
    width: 100%;
  }

  .top-info-con li {
    float: left;
    padding-bottom: 20px;
  }

  header .logo:after {
    height: 120px;
    right: 0px;
  }

  header .logo {
    margin: 0px;
    padding: 10px 0;
  }

  header .ownmenu:before {
    display: none;
  }

  header .ownmenu:after {
    display: none;
  }

  header .logo a {
    margin-top: 0px;
  }

  .is-sticky .sticky {
    position: relative !important;
  }

  header .logo {
    position: relative;
    width: 100%;
    text-align: center;
  }

  header nav {
    float: none;
    width: 100%;
  }

  header nav .ownmenu .indicator {
    display: block;
  }

  header nav li a {
    display: inline-block;
    width: 100%;
    float: none;
  }

  .ownmenu > li.nav-on-of span {
    line-height: 50px;
    display: inline-block;
    width: 100%;
  }

  .ownmenu > li.nav-on-of {
    text-align: center;
    color: #fff;
    font-size: 20px;
  }

  .ownmenu > li.nav-on-of span.title {
    margin: 0px;
  }

  .ownmenu > li > a {
    padding: 10px 0;
    text-align: left;
  }

  .ownmenu .indicator {
    display: block;
  }

  header .social {
    width: 100%;
    display: inline-block;
    text-align: center;
    margin-bottom: 20px;
  }

  header {
  }

  .procsss {
    position: relative;
    margin-top: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.8);
  }

  .what-we-do .rotatebox {
    width: 50%;
    float: left;
  }

  .what-we-do .rotatebox:nth-child(3) {
    width: 100%;
  }

  .procsss ul {
    padding: 30px 0px;
  }

  .procsss .media {
    margin-bottom: 30px;
  }

  .call-out {
    text-align: center;
  }

  .call-out h4 {
    line-height: 40px;
    margin-bottom: 20px;
  }

  .heading {
    width: 100%;
    margin-top: 30px;
  }

  .block-about h4 {
    margin-top: 30px;
  }

  .what-we-do .rotatebox {
    min-height: auto;
    line-height: normal;
  }

  .services .tab-content {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .emergency {
    text-align: center;
  }

  .emergency .text-right {
    text-align: center;
  }

  .latest-work .col-4 .item.filter-home {
    width: 100%;
  }

  .latest-work .col-4 .item.filter-home li {
    display: inline-block;
    width: auto;
  }

  .latest-work .col-4 .item.filter-home li a {
    padding: 0 10px;
    margin-top: -2px;
  }

  .latest-work .position-center-center {
    width: 100%;
    text-align: center;
  }

  .latest-work .col-4 .item {
    width: 33.3333%;
  }

  .clients ul li {
    float: left;
    width: 33.3333%;
    padding: 0px;
  }

  footer h4 {
    display: inline-block;
    width: 100%;
    margin: 50px 0;
    margin-bottom: 20px;
  }

  .footer-works > .col-md-3 {
    float: left;
    width: 33.3333%;
    margin: 0px;
    padding: 10px;
  }

  .latest-work .filter {
    min-height: 80px;
    display: inline-block;
    width: 100%;
  }

  .services .nav-tabs {
    display: inline-block;
    width: 100%;
  }

  .latest-work .col-4 .item {
    width: 50%;
  }

  .port-item img {
    width: 100%;
  }

  .latest-work .filter {
    top: 0px;
    position: relative;
    margin-bottom: 20px;
    min-height: auto;
  }

  .portfolio.style-2 li.item {
    width: 50% !important;
  }

  header {
    padding: 0px;
  }

  header .logo {
    width: auto;
    padding: 20px 0px;
    text-align: center;
    border: none;
    padding-bottom: 10px;
  }

  .feature-item {
    padding: 40px !important;
  }

  .list-style-featured li {
    margin-bottom: 10px;
  }

  .list-style-featured h4 {
    margin-top: 15px;
  }

  .testi {
    width: 100%;
  }

  .sub-footer {
    padding: 40px;
  }

  .ownmenu > li.nav-on-of {
    position: absolute;
    right: 0px;
    top: -65px;
    width: auto;
    padding: 0 20px;
    background: rgba(0, 0, 0, 0.5);
    text-align: right;
  }

  .work-process li {
    margin-bottom: 30px;
  }

  .team article {
    margin-bottom: 30px;
    text-align: center;
  }

  header .navbar {
    width: 100%;
  }

  .top-bar {
    width: 100%;
  }

  header .quotes a {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .top-info {
    display: none;
  }

  .heading p {
    width: 100%;
  }

  .members li {
    width: 50%;
    float: left;
  }

  .welcome .infro-con {
    margin-bottom: 30px;
  }

  .req-quote form {
    display: inline-block;
    width: 100%;
    margin-bottom: 50px;
  }

  .shipper {
    margin-top: 0px;
  }

  .req-quote .pull-right {
    width: 100%;
  }

  .process li {
    width: 50%;
    float: left;
    margin-bottom: 30px;
  }

  .team .team-detail {
    margin-bottom: 80px;
  }

  .in-testi {
    width: 100%;
  }

  .fst-up {
    margin-top: -100px;
  }

  .sub-space {
    padding-left: 0px;
  }

  .testimonial {
    background-size: cover;
  }

  .welcome article {
    margin-bottom: 30px;
  }

  .promos {
    text-align: center;
  }

  .promos .text-right {
    text-align: center;
  }

  footer .subcribe {
    margin-bottom: 0px;
  }

  .gallery .col-6 li {
    width: 33.3333%;
  }

  .gallery .col-6 li img {
    width: 100%;
  }

  .team .col-md-3 {
    width: 50%;
    float: left;
    margin-bottom: 30px;
  }

  .foot-info-con li {
    width: 50%;
    float: left;
    margin-bottom: 30px;
  }

  footer h6 {
    display: inline-block;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .media-filter {
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
  }

  .media-filter li {
    width: auto !important;
    margin-bottom: 0px;
  }
}

@media (max-width: 640px) {
  .main-bnr .media-body {
    display: inline-block;
    width: 100%;
  }

  .nav-bar-block {
    margin-top: 0px;
    left: 0px;
    padding: 0 20px;
    height: auto;
  }

  .nav-bar-block ul {
    text-align: center;
  }

  .nav-bar-block ul li a {
    margin: 0px 10px;
  }

  .nav-bar-block .listner {
    position: relative;
    width: 100%;
    right: 0px;
    text-align: center;
    display: inline-block;
  }

  .main-bnr {
    padding-top: 130px;
    padding-bottom: 30px;
  }

  .rights .social_icons {
    text-align: center;
  }

  .rights {
    text-align: center;
  }
}

@media (max-width: 540px) {
  .offer-services li {
    width: 100%;
  }

  .discover-style-2 .discover-music li {
    width: 100% !important;
  }

  .gallery li {
    width: 50%;
  }

  .gallery li img {
    width: 100%;
  }

  .news-letter input {
    width: 100%;
    margin: 0px;
    margin-bottom: 10px;
  }

  .news-letter form {
    max-width: 100%;
  }

  .portfolio.style-2 li.item {
    width: 100%;
  }

  .offer-services li .position-center-center {
    width: 100%;
  }

  .services .nav-tabs li .img-responsive {
    height: 180px;
  }

  .latest-work .col-4 .item {
    width: 100%;
  }

  .team ul li {
    width: 100%;
  }

  .team ul li {
    width: 100%;
  }

  .team .social li {
    width: auto;
  }

  .gallery .col-6 li {
    width: 50%;
  }

  .gallery .col-6 li img {
    width: 100%;
  }

  .team .col-md-3 {
    width: 100%;
    float: left;
    margin-bottom: 30px;
  }

  .discover-music li {
    width: 100%;
  }
}

@media (max-width: 440px) {
  .top-info-con ul li {
    width: 100%;
  }

  .top-info-con ul li.lst {
    width: 100%;
  }

  header .logo {
    max-width: 260px !important;
  }

  header .logo img {
    position: relative;
    top: 5px;
  }

  header .logo:after {
    display: none;
  }

  header .logo:before {
    display: none;
  }

  .process li {
    width: 100%;
  }

  .portfolio-wrapper .items .item {
    width: 100%;
  }

  .top-info-con ul li.fst {
    width: 100%;
  }

  .some-latest li h3 {
    margin: 0px;
  }

  .gallery .col-6 li {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .members li {
    width: 100%;
    float: left;
  }
}

@media (max-height: 700px) {
}

@media (min-width: 767px) and (max-width: 1200px) {
  header .logo {
    display: block;
    max-width: 100% !important;
    float: none !important;
    text-align: center;
  }

  header .logo img {
    max-width: 350px;
  }

  header .navbar {
    float: none !important;
    margin-top: 20px !important;
    text-align: center;
  }

  header .navbar .nav {
    float: none !important;
    display: inline-block;
  }

  .bnr-head .position-center-center {
    padding-top: 120px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .bnr-head {
    min-height: 650px !important;
  }
}

/* @media(min-width:767px) and (max-width:1200px) {
  .bnr-head {
    min-height: 650px !important;
  }
} */
