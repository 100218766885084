/* You can add global styles to this file, and also import other style files */
@import 'node_modules/bootstrap/dist/css/bootstrap.min.css'
@import "node_modules/fork-awesome/css/fork-awesome.min.css"

@import "theme/css/main.css"
@import "theme/css/responsive.css"
@import "theme/css/style.css"

@import "app/app.component.sass"

a
  cursor: pointer

  &:hover, &:focus, &:active
    color: $color-primary-darker

.media-filter li
  cursor: pointer

.border-top-20
  border-radius: 20px 20px 0 0

.card
  background-color: $color-bg-card
  padding: 20px 10px
.card-shadowed
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px

.card-bordered
  border-radius: $border-radius
.card-bordered-larger
  border-radius: $border-radius-larger

.warn
  font-style: italic

.advert
  color: $color-font
  font-size: 18px
  font-style: italic
  display: block
  text-align: center
  z-index: 99999
  font-family: "Montserrat"
  font-weight: 500

.btn-platform
  border-radius: $border-radius
  background-color: $color-primary
  padding: 8px
  color: white !important
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px
  text-align: center

  i
    width: 17px

  &:hover, &:active, &:focus
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px
    background-color: $color-primary-dark

.fa-external-link
  font-size: 12px
  padding-left: 1px

.badge-primary
  background-color: $color-primary

.search-pro
  background: rgba(255,255,255,0.8)
  border-radius: $border-radius-larger $border-radius-larger 0 0

select
  border-radius: 4px
  height: 40px
  padding: 0 10px
  width: 100%
  color: #999999
  font-size: 14px
  background: #2e2e2e
  border: none

input
  color: #999
  background: #2e2e2e

a, h2, h3, h4
  color: $color-primary

p
  color: $color-font

.search-pro
  z-index: 9
