.btn
  font-weight: 500
  border: none
  background-color: $color-primary
  border-radius: $border-radius-larger

  &:hover,
  &:active,
  &:focus
    background: $color-primary-dark

.btn-trans
  background: none

  &:hover,
  &:active,
  &:focus
    color: #080808
    background: white

.map-search button
  font-weight: 500
  border: none
  background-color: $color-bg-primary
  border-radius: $border-radius-larger
  transition: all .4s ease-in-out

  &:hover,
  &:active,
  &:focus
    background: $color-primary-dark

.btn-link
  color: $color-font
