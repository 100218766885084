/*------------------------------------------------------------------
    Main Style Stylesheet
    Project:        HTML5 Template
    Version:        1.0
    Author:         M_Adnan
    Last change:    10/02/2015
    Primary use:    Creative, Coporate, Business, Portfolio, Personal
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
body
 + WRAP
 + HEADER
 + NAVIGATION
 + BANNER
 + SERVICES
 + OUR FEATURED FOUNDERS
 + BLOG UPDATES
 + APPOINMENT
 + NEWS FROM BLOG
 + PARTNERS / CLIENTS
 + FOOTER
 + SOCIAL ICONS
 + MEGA MENU
 + 3 MENU LEVEL
-------------------------------------------------------------------*/

/*=======================================================
      THEME STYLING START
========================================================*/
* {
  margin: 0px;
  padding: 0px;
}

body {
  background: black;
  font-weight: normal;
  position: relative;
  font-size: 14px;
}

.map-tiles {
  filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3)
    brightness(0.7);
}

/*=======================================================
      WRAPPER
========================================================*/
#wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
}

/*=======================================================
      HEADINGS
========================================================*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  color: #252525;
  font-weight: bold;
  letter-spacing: 0px;
  margin-top: 0;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 24px;
}

h6 {
  font-size: 20px;
  font-weight: bold;
}

p {
  font-weight: 400;
  color: #6f6f6f;
  line-height: 22px;
  font-size: 14px;
  text-rendering: optimizeLegibility;
}

a {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  text-decoration: none !important;
}

img {
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -ms-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  display: inline-block !important;
}

ul {
  margin-bottom: 0px;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #141414;
}

p.intro-small {
  width: 80%;
  margin: 0 auto;
}

.text-transform-none {
  text-transform: none !important;
}

.text-white {
  color: #fff !important;
}

.text-bold {
  font-weight: bold !important;
}

.text-normal {
  font-weight: normal !important;
}

.font-12px {
  font-size: 12px !important;
}

.font-14px {
  font-size: 14px !important;
}

.font-16px {
  font-size: 16px !important;
}

.font-18px {
  font-size: 18px !important;
}

.font-20px {
  font-size: 20px !important;
}

.font-bold {
  font-weight: bold !important;
}

.font-normal {
  font-weight: normal !important;
}

.white-text {
  color: #fff !important;
}

.font-italic {
  font-style: italic;
}

.font-bold {
  font-weight: bold !important;
}

a:hover,
a:focus {
  color: #00b1f1;
}

.primary-color {
  color: #08ada7 !important;
}

.border-bottom-1 {
  border-bottom: 1px solid #ededed;
}

.position-center-center {
  left: 50%;
  position: absolute;
  top: 50%;
  padding-top: 100px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.white-bg {
  background: #fff !important;
}

.relative {
  position: relative;
}

.line-height-24 {
  line-height: 24px !important;
}

.line-height-22 {
  line-height: 22px !important;
}

.line-height-26 {
  line-height: 26px !important;
}

.dark-text p {
  color: #141414;
}

.light-gray-bg {
  background: #f6f7f8 !important;
}

.customcolor1 {
  color: #448aff;
}

/*=======================================================
      BTN STYLE
========================================================*/
.btn {
  border: none;
  color: #fff;
  display: inline-block;
  padding: 2px 21px;
  font-weight: normal;
  font-size: 14px;
  border-radius: 0px;
  line-height: 40px;
  background: rgba(0, 0, 0, 0.6);
  position: relative;
  z-index: 1;
}

.btn:hover {
  color: #fff;
}

.btn-white {
  background: #fff;
  color: #ab4e52 !important;
}

.btn-white:hover {
  background: #ab4e52;
  color: #fff !important;
}

.tp-caption .btn {
  display: inline-block;
}

.btn-1 {
  border: 1px solid #965452;
  border-left: none;
  border-right: none;
  border-top-color: transparent;
  text-transform: uppercase;
  font-size: 10px;
  display: inline-block;
  padding: 10px;
  letter-spacing: 1px;
  color: #965452;
}

.btn-1 i {
  font-size: 10px;
  margin-left: 20px;
}

.btn-1.btn-2 {
  border-bottom-color: transparent;
  border-top: 1px solid #965452;
}

.btn-1:hover {
  color: #fff;
  background: #965452;
}

.parallax-bg {
  background-size: cover !important;
}

section {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #fff;
}

.text-shadow {
  text-shadow: 3px 0px 5px rgba(0, 0, 0, 0.5);
}

.text-shadow-1 {
  text-shadow: 2px 3px 0 rgba(0, 0, 0, 0.5);
}

.home-slider {
  position: relative;
}

/*=======================================================
      HEADER
========================================================*/
header {
  width: 100%;
  z-index: 99;
  position: absolute;
  background: #fff;
  padding: 40px 0;
  background: none;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.5+0,0+100 */
  /* background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80ffffff', endColorstr='#00ffffff', GradientType=0); */
}

header .navbar {
  border: none;
  margin: 0px;
  padding: 0px;
  border-radius: 0px;
  text-align: center;
  width: 70%;
  float: right;
  display: block;
  width: auto;
}

header .logo {
  float: left;
  position: relative;
  max-width: 300px;
}

header .logo img {
  z-index: 3;
  position: relative;
  width: 100%;
}

.sticky-wrapper {
  height: auto !important;
}

header .navbar {
  min-height: auto;
  margin-top: 5px;
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -ms-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

header .navbar li {
  display: inline-block;
  margin: 0 20px;
  position: relative;
  padding: 5px 0;
}

header .navbar li.active {
  margin-bottom: 15px;
}

header .sticky {
  width: 100%;
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -ms-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

header .navbar li a {
  font-size: 16px;
  color: #2d3a4b;
  padding: 0px 0px;
  padding-bottom: 10px;
  letter-spacing: 0px;
  text-transform: none;
  border-bottom: 3px solid;
  border-color: transparent;
}

header .nav {
  border-radius: 5px;
}

header .nav .active a {
  background: none;
}

header .nav > li > a:focus,
.nav > li > a:hover {
  background: none;
}

.ownmenu ul.dropdown {
  background: #222;
  padding: 0px;
}

.ownmenu ul.dropdown li {
  margin: 0px;
}

.ownmenu ul.dropdown li {
  position: relative;
  z-index: 2;
}

.ownmenu ul.dropdown li a {
  z-index: 1;
  position: relative;
}

.ownmenu ul.dropdown li a:before {
  background: #ab4e52;
  content: "";
  width: 0px;
  height: 100%;
  top: 0px;
  margin: 0px;
  z-index: -1;
  left: 0px;
}

.ownmenu ul.dropdown li a:hover:before {
  width: 100%;
}

.ownmenu ul.dropdown li a {
  padding: 2px 20px;
  font-size: 12px;
  font-weight: normal;
  line-height: 34px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.ownmenu ul.dropdown li a:hover {
  border-bottom: 1px solid #8e44ad;
}

.ownmenu ul.dropdown {
  top: 36px;
}

.ownmenu {
  float: right;
}

.nav-right i {
  font-size: 20px;
}

.nav-right .navbar-right li {
  margin: 0 10px;
}

.nav-right {
  float: right;
  margin-top: 15px;
  position: relative;
}

header .social_icons {
  float: right;
  color: #fff;
  margin-top: 20px;
}

header .social_icons a {
  color: #fff;
  margin-left: 10px;
}

header .social_icons a:hover {
  color: #ab4e52;
}

.heading {
  margin: 0px;
  margin-bottom: 30px;
}

.featured .cover-al span {
  display: inline-block;
  width: 100%;
}

.featured .cover-al .btn {
  margin-top: 20px;
}

.featured .cover-al .btn:hover {
  background: #8e44ad;
}

.bnr-head h3 {
  margin-bottom: 30px;
  /* margin-top: 80px; */
  font-size: 38px;
}

.bnr-head p {
  margin: 0 auto;
  max-width: 590px;
  font-size: 15px;
  color: #fff !important;
}

.bnr-head .btn {
  background: #8e44ad;
  color: #fff;
}

.discover {
  background: url("/assets/images/discover-bg.jpg.webp") no-repeat;
  background-size: cover;
  padding: 80px 0;
}

.main-heading {
  text-align: center;
  margin-bottom: 40px;
}

.main-heading h3 {
  font-size: 28px;
  text-transform: uppercase;
  margin: 0px;
  margin-bottom: 10px;
}

.main-heading hr {
  border: none;
  background: #8e44ad;
  height: 3px;
  width: 100px;
  margin: 0 auto;
}

.discover-music li {
  margin-bottom: 30px;
}

.discover-music img {
  width: 100%;
}

.discover-music h6 {
  color: #fff;
  font-size: 18px;
  background: #261e32;
  text-align: center;
  margin: 0px;
  padding: 15px 0;
  font-weight: normal;
}

.pic-dis.discover-music a {
  color: #fff;
  font-size: 18px;
  background: #261e32;
  text-align: center;
  margin: 0px;
  padding: 15px 0;
  display: inline-block;
  width: 100%;
  font-weight: normal;
}

.pic-dis.discover-music a:hover {
  background: #8e44ad;
}

.filter-index .item-name h6 {
  padding: 0px;
}

.filter-index .item-name span {
  text-transform: uppercase;
  margin-bottom: 5px;
}

.filter-index .item-name {
  padding: 20px 25px !important;
  padding-top: 15px !important;
}

.latest-release {
  padding: 80px 0;
}

.latest-release article {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}

.latest-release article .botm-text {
  position: absolute;
  padding-bottom: 20px;
  padding-left: 20px;
  z-index: 1;
  left: 0px;
  bottom: 0px;
  padding-top: 150px;
  width: 100%;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
  background: -moz-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0);
  /* IE6-9 */
}

.latest-release article .botm-text span {
  display: inline-block;
  width: 100%;
  color: #c1babe;
  font-size: 12px;
  margin-bottom: 5px;
}

.latest-release article .botm-text a {
  font-size: 20px;
  color: #fff;
}

.latest-release article .botm-text a:hover {
  color: #8e44ad;
}

.discover-style-2 {
  text-align: center;
}

.discover-style-2 .center-part {
  height: 212px;
  width: 212px;
  border-radius: 50%;
  font-size: 24px;
  color: #fff;
  position: relative;
  display: inline-block;
  background: #8e44ad;
  text-transform: uppercase;
  text-align: center;
  line-height: 30px;
  margin: 0 auto;
  margin-top: 110px;
}

.discover-style-2 .outer {
  height: 434px;
  width: 434px;
  border: 2px solid #fff;
  margin: 140px auto;
  border-radius: 50%;
  position: relative;
  margin-bottom: 100px;
}

.discover-style-2 .discover-music li img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 5px solid #fff;
}

.discover-style-2 .discover-music li a {
  border: none;
  background: none;
  text-transform: uppercase;
  color: #fff;
  font-size: 18px;
  margin: 0px;
  padding: 10px 0;
  font-weight: normal;
}

.discover-style-2 .discover-music li a:hover {
  color: #8e44ad;
}

.discover-style-2 .discover-music li:nth-child(1) {
  min-width: 100px;
  position: absolute;
  margin: 0 auto;
  top: -100px;
  left: 0px;
  right: 0px;
}

.discover-style-2 .discover-music li:nth-child(1) a {
  display: inline-block;
  width: 100%;
}

.discover-style-2 .discover-music li:nth-child(2) {
  min-width: 300px;
  position: absolute;
  margin: 0 auto;
  top: 50px;
  left: -170px;
}

.discover-style-2 .discover-music li:nth-child(2) a {
  float: left;
  margin-top: 26px;
}

.discover-style-2 .discover-music li:nth-child(3) {
  min-width: 300px;
  position: absolute;
  margin: 0 auto;
  top: 50px;
  right: -190px;
}

.discover-style-2 .discover-music li:nth-child(3) a {
  float: right;
  margin-top: 31px;
}

.discover-style-2 .discover-music li:nth-child(4) {
  min-width: 300px;
  position: absolute;
  margin: 0 auto;
  top: 220px;
  left: -200px;
}

.discover-style-2 .discover-music li:nth-child(4) a {
  float: left;
  margin-top: 26px;
}

.discover-style-2 .discover-music li:nth-child(5) {
  min-width: 300px;
  position: absolute;
  margin: 0 auto;
  top: 220px;
  right: -200px;
}

.discover-style-2 .discover-music li:nth-child(5) a {
  float: right;
  margin-top: 26px;
}

.discover-style-2 .discover-music li:nth-child(6) a {
  display: inline-block;
  width: 100%;
}

.discover-style-2 .discover-music li:nth-child(7) a {
  display: inline-block;
  width: 100%;
}

.discover-style-2 .discover-music li:nth-child(6) {
  min-width: 100px;
  position: absolute;
  margin: 0 auto;
  top: 360px;
  left: 40px;
}

.discover-style-2 .discover-music li:nth-child(7) {
  min-width: 100px;
  position: absolute;
  margin: 0 auto;
  top: 360px;
  right: 40px;
}

.discover-style-2 .discover-music li:hover img {
  border-color: #8e44ad;
}

/*=======================================================
      MAIN BANNER
========================================================*/
.main-bnr {
  background: url("/assets/images/header-bg-light.jpg.webp") center center
    no-repeat;
  background-size: cover;
  min-height: 582px;
  padding-top: 260px;
}

.main-bnr h1 {
  font-size: 42px;
  margin: 0px;
  margin-bottom: 10px;
  text-transform: none;
}

.main-bnr p {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
  line-height: 24px;
  color: #252525;
}

.main-bnr p i {
  width: 15px;
  display: inline-block;
}

.main-bnr .flowers {
  margin-top: 10px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.8);
  max-width: 400px;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.main-bnr .flowers span {
  font-size: 14px;
  margin-right: 50px;
  color: #666666;
}

.main-bnr .btn {
  background: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  font-weight: normal;
  padding: 0px 18px;
  text-transform: none;
  letter-spacing: 0px;
  height: 40px;
  line-height: 40px;
}

.main-bnr .btn:hover {
  background: rgba(0, 0, 0, 1);
  color: #fff;
}

.main-bnr .btn i {
  margin-right: 10px;
}

.main-bnr .avatar {
  display: inline-block;
  width: 198px;
  height: 198px;
  margin-right: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.nav-bar-block {
  background: rgba(255, 255, 255, 0.9);
  height: 65px;
  width: 100%;
  padding: 0 40px;
  margin-top: -65px;
  z-index: 9;
  position: relative;
}

.nav-bar-block ul li {
  display: inline-block;
  font-size: 16px;
}

.nav-bar-block .listner {
  position: absolute;
  right: 40px;
  top: 0px;
  line-height: 65px;
}

.nav-bar-block ul li a {
  line-height: 65px;
  margin-right: 30px;
  color: #999999;
  border-bottom: 5px solid;
  border-color: transparent;
  padding-bottom: 18px;
  padding-top: 23px;
}

.nav-bar-block ul li a:hover {
  border-color: #8e44ad;
  color: #8e44ad;
}

.nav-bar-block ul li a.active {
  border-color: #8e44ad;
  color: #8e44ad;
}

.featured {
  background: url("/assets/images/bg-3.jpg.webp") center center no-repeat;
  background-size: cover;
}

.main-bnr .modal .modal-dialog {
  max-width: 100%;
  width: 100%;
  margin-top: 0px;
  background: rgba(0, 0, 0, 0.7);
  -webkit-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.main-bnr .modal {
  background: rgba(0, 0, 0, 0.2);
  padding: 0 !important;
}

.main-bnr .modal-backdrop.in {
  background: none;
}

.main-bnr .modal ul {
  padding: 0px;
}

.main-bnr .modal form input {
  border: none;
  font-size: 14px;
  font-weight: normal;
  height: 45px;
  width: 100%;
  color: #979797;
  padding: 0px 20px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.main-bnr .modal form textarea {
  border-radius: 4px;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px 20px;
}

.main-bnr .modal h6 {
  color: #fff;
  font-size: 18px;
  font-weight: normal;
  margin: 0px;
  text-align: center;
  margin-bottom: 40px;
}

.main-bnr .modal h6 a {
  color: #fff;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  color: #fff;
  float: none;
  opacity: 1;
  text-align: center;
  line-height: 20px;
}

.main-bnr .modal button {
  margin: 0 auto;
  width: 150px;
  background: rgba(255, 255, 255, 0.1);
}

.main-bnr .modal .modal-content {
  padding: 50px 0;
}

.modal-open {
  overflow: inherit !important;
  padding: 0px !important;
}

.main-bnr .modal form textarea {
  border: none;
  font-size: 14px;
  font-weight: normal;
  height: 100px;
}

.main-bnr .modal ul li {
  list-style: none;
}

.main-bnr .modal-content {
  background: none;
  box-shadow: none;
  border: none;
}

/*=======================================================
      Block About
========================================================*/
.block-about {
  background: url("/assets/images/about-block-bg.jpg.webp") center center
    no-repeat;
  background-size: cover;
  padding: 80px 0;
}

.about-inn {
  background: #fff;
  padding: 50px 40px;
}

.about-inn p {
  margin: 0px;
}

.block-about h4 {
  margin-top: 0px;
  margin-bottom: 30px;
}

/*=======================================================
      NEW ARRIVAL
========================================================*/
.videos h4 a {
  font-size: 14px;
  color: #8e44ad;
  font-weight: normal;
  margin-left: 30px;
}

.videos ul {
  background: #ededed;
}

.videos ul {
  max-height: 400px;
  overflow-y: scroll;
}

.videos ul li a {
  display: inline-block;
  width: 100%;
  padding: 20px;
}

.videos ul li p {
  margin-bottom: 0px;
}

.videos ul li span {
  font-weight: normal;
  font-size: 12px;
}

.videos ul li {
  border-left: 2px solid;
  border-color: transparent;
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -ms-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.videos ul li:hover {
  background: #fff;
  border-color: #8e44ad;
}

.videos ul li.active {
  background: #fff;
  border-left-color: #8e44ad;
}

.videos iframe {
  height: 400px;
}

iframe {
  border: none;
  width: 100%;
}

.news-post article {
  padding: 30px;
  padding-top: 0px;
}

.news-post article .read-more {
  color: #8e44ad;
  margin-top: 10px;
  display: inline-block;
}

.news-post span {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  padding-left: 10px;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: normal;
}

.news-post .post-tittle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
}

.gallery {
  margin: 0 -10px;
}

.gallery li {
  float: left;
  width: 25%;
  padding: 10px;
}

.gallery li a:hover {
  opacity: 0.5;
}

.news-letter h5 {
  font-weight: normal;
  margin-bottom: 30px;
}

.news-letter {
  text-align: center;
  background: url("/assets/images/new-bg.jpg.webp") center center no-repeat;
  background-size: cover;
}

.news-letter input {
  width: 289px;
  margin: 0 10px;
  background: #e4e4e4;
  border-radius: 4px;
  border: none;
  height: 40px;
  padding: 0 10px;
  display: inline-block;
  float: left;
  margin-bottom: 20px;
}

.news-letter form {
  max-width: 620px;
  margin: 0 auto;
}

.news-letter .btn:hover {
  background: #8e44ad;
}

.register form {
  padding: 60px 40px;
  background: #261e32;
  text-align: center;
  min-height: 420px;
  padding-bottom: 30px;
}

.register form label {
  display: inline-block;
  width: 100%;
  font-weight: normal;
  font-size: 14px;
}

.register form h4 {
  text-transform: uppercase;
  text-align: center;
  font-weight: normal;
  margin-bottom: 30px;
  margin-top: 0px;
}

.register form input {
  background: #000000;
  border-radius: 4px;
  border: none;
  height: 40px;
  margin-bottom: 20px;
  padding: 0 20px;
  display: inline-block;
  width: 100%;
}

.register form .checkbox {
  text-align: center;
  display: inline-block;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 25px;
}

.register form .forget {
  color: #8e44ad;
  width: 100%;
  display: inline-block;
  margin-top: 10px;
}

.register form .checkbox label {
  width: auto;
  color: #aaa9ad;
}

.register form .checkbox input {
  color: #6c6971;
  width: auto;
  padding-left: 0px;
}

.checkbox label::before {
  margin-left: 2px;
  border-radius: 4px;
  background: #000000;
  height: 18px;
  width: 18px;
  border: none;
}

.checkbox label::after {
  color: #8e44ad;
}

.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  top: 0;
  margin-left: 0px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #8e44ad;
  line-height: 18px;
}

.register form .btn {
  background: #8e44ad;
  text-transform: uppercase;
  border-radius: 4px;
  min-width: 200px;
}

.register form .btn:hover {
  background: #000;
}

/*=======================================================
      SEARCH BAR
========================================================*/
.search-bar {
  background: rgba(0, 0, 0, 0.8);
  min-height: 80px;
  margin-top: -80px;
  position: relative;
  z-index: 99;
  padding: 20px 0;
}

.search-bar input {
  border-radius: 4px;
  height: 40px;
  padding: 0 10px;
  width: 100%;
  font-size: 14px;
  background: #2e2e2e;
  border: none;
}

.search-pro {
  background: rgba(0, 0, 0, 0.8);
  min-height: 80px;
  margin-top: -80px;
  position: relative;
  z-index: 99;
  padding: 20px 0;
}

.search-pro form {
  margin-bottom: 0px;
}

.search-pro form button {
  border-radius: 4px;
  background: #f1f1f1;
  border: none;
  height: 35px;
  font-size: 12px;
  padding: 0px 20px;
}

.search-pro form {
  padding-left: 50px;
}

.search-pro form input {
  height: 40px;
  color: #fff;
  font-size: 14px;
  margin-bottom: 0px;
  border: 1px solid #eaeaea;
}

.map-search button {
  border-radius: 4px;
  background: #8e44ad;
  border: none;
  min-width: 150px;
  height: 40px;
  color: #fff;
  padding: 0px 20px;
}

.map-search button:hover {
  background: #333;
}

.map-search .map-toggleButton {
  position: absolute;
  top: 0;
  left: 15px;
  display: inline-block;
  width: 50px;
  height: 35px;
  background: #f1f1f1;
  border-radius: 3px;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.map-search .map-toggleButton:hover {
  background: #;
}

.map-search .map-toggleButton:hover .fa {
  color: #fff;
}

.map-search .map-toggleButton .fa {
  color: #999999;
  font-size: 16px;
}

.map-search .map-search-fields {
  position: relative;
  padding: 0 190px 0 0px;
}

@media (max-width: 992px) {
  .map-search .map-search-fields {
    padding-right: 0;
  }
}

.map-search .map-search-fields .field {
  position: relative;
  margin-right: 13px;
  width: 32.333%;
  float: left;
  display: inline-block;
}

@media (max-width: 1200px) {
  .map-search .map-search-fields .field {
    width: 32%;
  }
}

@media (max-width: 768px) {
  .map-search .map-search-fields .field {
    margin-bottom: 5px;
    width: 100%;
  }
}

.map-search .map-search-fields .field:last-child {
  margin-right: 0;
}

.map-search .map-search-fields .field .fa {
  position: absolute;
  left: 18px;
  top: 14px;
  color: #999;
  z-index: 99;
}

.map-search .map-search-fields .field input {
  border-radius: 4px;
  height: 40px;
  padding: 0 10px;
  width: 100%;
  font-size: 14px;
  border: none;
}

.map-search .map-search-fields .field input.location {
  padding-left: 36px;
}

.map-search .map-search-fields .field select {
  height: 40px !important;
}

.map-search .map-search-fields .field .select2-selection__arrow {
  height: 43px !important;
  border-left: none;
}

.map-search
  .map-search-fields
  .field
  .select2-container
  .select2-selection--single {
  height: 45px;
}

.map-search
  .map-search-fields
  .field
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  line-height: 42px;
}

.map-search .search-button {
  position: absolute;
  right: 15px;
  top: 0;
}

@media (max-width: 992px) {
  .map-search .search-button {
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: inline-block;
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 992px) {
  .map-search .search-button .btn {
    margin-top: 10px;
  }
}

/*=======================================================
      NEW ARRIVAL
========================================================*/
.arrival-block {
  border-top: 1px solid #d1d5db;
  border-left: 1px solid #d1d5db;
  display: inline-block;
  width: 100%;
}

.arrival-block .item {
  position: relative;
  width: 25%;
  float: left;
  overflow: hidden;
  min-height: 509px;
  border-right: 1px solid #d1d5db;
  border-bottom: 1px solid #d1d5db;
}

.arrival-block .overlay {
  background: rgba(255, 255, 21, 0.75);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  opacity: 0;
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -ms-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.arrival-block .item:hover .overlay {
  opacity: 1;
}

.arrival-block .overlay a {
  height: 71px;
  width: 71px;
  display: inline-block;
  border-radius: 50%;
  background: #52594a;
  text-align: center;
  line-height: 71px;
  color: #fff;
}

.arrival-block .price {
  position: absolute;
  right: 20px;
  top: -50px;
  font-size: 18px;
  font-weight: bold;
  z-index: 9;
  color: #2d3a4b;
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -ms-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.arrival-block .price small {
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
}

.arrival-block .item:hover .price {
  top: 20px;
}

.arrival-block .overlay a:hover {
  background: #fff;
  color: #52594a;
}

.arrival-block .item img {
  width: 100%;
  margin-top: 0px;
  top: 0px;
  left: 0px;
  position: relative;
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -ms-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.arrival-block .item:hover .img-1 {
  left: -100%;
  position: absolute;
}

.arrival-block .item .img-2 {
  position: absolute;
  left: 100%;
}

.arrival-block .item:hover .img-2 {
  left: 0px;
}

.arrival-block .item-name {
  position: absolute;
  bottom: 50px;
  width: 100%;
  text-align: center;
  z-index: 9;
}

.arrival-block .item-name p {
  font-size: 14px;
  margin: 0px;
  margin-top: 10px;
}

.arrival-block .item-name a {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 3px;
  color: #2d3a4b;
  text-transform: uppercase;
}

.about-page {
  background: #19151d;
}

.about-page h4 {
  margin-bottom: 20px;
}

.about-page .per-info {
  background: rgba(0, 0, 0, 0.5);
  padding: 40px;
}

.about-page p {
  font-size: 14px;
  line-height: 22px;
}

.about-page .social_icons {
  text-align: left;
  margin-top: 50px;
}

.about-page .social_icons li {
  display: inline-block;
}

.about-page .social_icons li a {
  color: #a3a3a3;
  font-size: 12px;
  display: inline-block;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 28px;
  margin: 0 2px;
  border: 1px solid #a3a3a3;
}

.about-page .social_icons li a:hover {
  background: #fff;
  color: #333;
}

.media-item {
  text-align: center;
}

.media-item h3 {
  display: inline-block;
  width: 100%;
  text-align: center;
  line-height: 20px;
  margin: 0px;
}

.media-item a {
  color: #fff;
}

.media-item span {
  opacity: 0;
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -ms-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  margin-bottom: 10px;
  display: inline-block;
}

.media-item:hover span {
  opacity: 1;
}

.media-image {
}

.media-filter li {
  display: inline-block;
  margin-bottom: 20px;
}

.media-filter li a {
  padding: 8px 20px;
  display: inline-block;
  color: #999999;
  font-size: 15px;
  font-weight: normal;
}

.media-filter li a:hover {
  background: #8e44ad;
  color: #fff;
}

.media-filter li a.active {
  background: #8e44ad;
  color: #fff;
}

.discover-music article .view-detail {
  border: 2px solid #fff;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  padding: 8px 20px;
  opacity: 0;
  position: relative;
  top: -40px;
  text-transform: uppercase;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.discover-music article .view-detail:hover {
  background: #8e44ad;
  border-color: #8e44ad;
}

.discover-music article:hover .view-detail {
  opacity: 1;
}

.discover-music article .tag {
  position: absolute;
  top: 10px;
  left: 30px;
  height: 30px;
  color: #fff;
  width: 30px;
  text-align: center;
  line-height: 30px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.3);
}

.discover-music .item-name {
  background: #261e32;
  padding: 25px;
}

.discover-music .item-name h6 {
  font-weight: normal;
  margin: 0px;
  font-size: 18px;
}

.discover-music .item-name span {
  color: #bdbcbf !important;
  font-size: 10px;
  opacity: 1;
}

/*=======================================================
      PAPULAR ITEM
========================================================*/
.papular-block .item-img {
  position: relative;
  overflow: hidden;
  min-height: 352px;
}

.papular-block .owl-prev {
  position: absolute;
  top: 50%;
  left: -50px;
  height: 23px;
  width: 23px;
  border: 1px solid #000;
  text-align: center;
  line-height: 21px;
}

.papular-block .owl-next {
  position: absolute;
  top: 50%;
  right: -50px;
  height: 23px;
  width: 23px;
  border: 1px solid #000;
  text-align: center;
  line-height: 21px;
}

.papular-block .overlay {
  background: rgba(255, 255, 21, 0.75);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  opacity: 0;
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -ms-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.papular-block .item:hover .overlay {
  opacity: 1;
}

.papular-block .item-img {
  margin-bottom: 30px;
}

.papular-block .overlay .inn {
  display: inline-block;
  border-radius: 30px;
  background: #52594a;
  text-align: center;
  color: #fff;
  height: 45px;
  margin: 10px 0;
  padding: 0 20px;
}

.papular-block .position-center-center {
  width: 100%;
  text-align: center;
}

.papular-block .overlay .inn a {
  display: inline-block;
  font-size: 16px;
  color: #fff;
  margin: 0 8px;
  line-height: 45px;
}

.papular-block .price {
  font-size: 18px;
  font-weight: bold;
  z-index: 9;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  color: #2d3a4b;
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -ms-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.papular-block .price small {
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
}

.papular-block .overlay a:hover {
}

.papular-block .item img {
  width: 100%;
  margin-top: 0px;
  top: 0px;
  left: 0px;
  position: relative;
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -ms-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.papular-block .item:hover .img-1 {
  left: -100%;
  position: absolute;
}

.papular-block .item .img-2 {
  position: absolute;
  left: 100%;
}

.papular-block .item:hover .img-2 {
  left: 0px;
}

.papular-block .item-name {
  width: 100%;
  text-align: center;
  z-index: 9;
}

.papular-block .item-name p {
  font-size: 14px;
  margin: 0px;
  margin-top: 10px;
}

.papular-block .item-name a {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 3px;
  color: #2d3a4b;
  text-transform: uppercase;
}

/*=======================================================
      SUB BANNER
========================================================*/
.sub-bnr {
  background: url("/assets/images/sub-bnr.jpg.webp") center center no-repeat;
  background-size: cover !important;
  min-height: 400px;
  text-align: center;
  position: relative;
}

.sub-bnr h2 {
  color: #fff;
  font-size: 42px;
  font-weight: bold;
  letter-spacing: 0px;
  margin-top: 30px;
  text-transform: uppercase;
}

.sub-bnr p {
  font-style: italic;
  color: #fff;
  letter-spacing: 1px;
  font-size: 14px;
  margin-top: 20px;
}

.sub-bnr .breadcrumb {
  background: none;
  margin: 0px;
  padding: 0px;
}

.sub-bnr h6 {
  display: inline-block;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  float: left;
  line-height: 58px;
  margin: 0px;
  letter-spacing: 1px;
}

.sub-bnr .breadcrumb li {
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 30px;
  font-weight: 700;
  font-size: 12px;
  margin: 10px 0;
}

.sub-bnr .breadcrumb li a {
  color: #fff;
  text-transform: none;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
}

.sub-bnr .breadcrumb > li + li:before {
  color: #fff;
}

.rights .go-up {
  border: 1px solid #454545;
  color: #949494;
  height: 30px;
  width: 30px;
  float: right;
  position: absolute;
  right: 30px;
  top: 20px;
  text-align: center;
  line-height: 30px;
  display: inline-block;
}

.rights .go-up:hover {
  background: #fff;
  color: #333;
}

.rights {
  background: #ffffff;
  padding: 20px 0;
  text-align: left;
  position: relative;
}

.rights p {
  font-weight: 500;
  color: #666666;
  margin: 0px;
  font-size: 13px;
}

.rights a {
  color: #8e44ad;
}

.rights .fa-heart {
  color: red;
}

.rights .social_icons {
  text-align: right;
  margin-top: 12px;
}

.rights .social_icons li {
  display: inline-block;
}

.rights .social_icons li a {
  color: #a3a3a3;
  font-size: 16px;
  margin: 0 5px;
}

.light-rights {
  background: #eceaea;
}

.news-list .news-post {
  margin-bottom: 30px;
  position: relative;
}

.news-list .news-post .post-img {
  display: inline-block;
  width: 263px;
  margin-right: 20px;
}

.news-list .news-post .post-img .date {
  background: #8e44ad;
  width: 70px;
  position: absolute;
  top: -10px;
  padding-top: 8px;
  text-align: center;
  font-size: 32px;
  color: #fff;
  font-weight: bold;
  left: 20px;
  border-radius: 4px;
}

.news-list .news-post .post-img .date span {
  float: left;
  width: 100%;
  background: #19151d;
  font-size: 12px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  line-height: 24px;
  padding: 0px;
  margin: 0px;
  margin-top: 8px;
}

.pagination > li > a,
.pagination > li > span {
  background: none;
  color: #666666;
  border: none;
  border-radius: 4px;
  margin: 0 2px;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #fff;
  background: #2f2837;
}

.pagination a.active {
  color: #fff;
  background: #2f2837;
}

.news-list .news-post .post-tittle {
  font-size: 24px;
  margin-bottom: 0px;
}

.news-list .news-post span {
  padding: 0px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.news-list .news-post p {
  color: #aeadaf;
}

/*=======================================================
      DARK LAYOUT
========================================================*/
.dark-layout .main-bnr {
  background: url("/assets/images/artist_banner_default.jpg.webp") center center
    no-repeat;
  background-size: cover;
}

.dark-layout header .navbar li a {
  color: #fff;
}

/* .dark-layout header {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80ffffff', endColorstr='#00ffffff', GradientType=0);
} */

.dark-layout h1 {
  color: #fff;
}

.dark-layout .rights p {
  color: #999999;
}

.dark-layout .main-bnr .flowers span {
  color: #fff;
}

.dark-layout .nav-bar-block {
  background: rgba(0, 0, 0, 0.9);
}

.dark-layout .block-about {
  background: url("/assets/images/about_banner.jpg.webp") center center
    no-repeat;
  background-size: cover;
}

.dark-layout .about-inn {
  background: #06162b;
}

.dark-layout .light-gray-bg {
  background: #282a41 !important;
}

.dark-layout .videos ul li p {
  color: #939393;
}

.dark-layout .videos ul li.active {
  background: #18141d;
}

.dark-layout .videos ul li.active p {
  color: #fff;
}

.dark-layout .videos ul li:hover {
  background: #18141d;
  border-left-color: #8e44ad;
}

.dark-layout .videos ul li:hover p {
  color: #fff;
}

.dark-layout .videos ul {
  background: #1e1c2d;
}

.dark-layout .news-post article {
  background: #211b2c;
}

.dark-layout .featured p {
  color: #ffffff;
}

.dark-layout .featured .cover-al span {
  color: #a9a8ab;
}

.dark-layout .news-letter {
  background: url("/assets/images/newsletter_banner.jpg.webp") center center
    no-repeat;
  background-size: cover;
}

.dark-layout .featured {
  background: url("/assets/images/about_banner.jpg.webp") center center
    no-repeat;
  background-size: cover;
}

.dark-layout .news-post span {
  color: #a9a8ac;
}

.dark-layout .news-post .post-tittle {
  color: #fff;
}

.dark-layout .rights {
  background: #000;
}

.dark-layout .videos ul li span {
  color: #939393;
}

.dark-layout .nav-bar-block .listner {
  color: #999;
}

.dark-layout .main-bnr p {
  color: #fff;
}

.videos.playlist li {
  padding: 10px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.videos.playlist li button {
  background: none;
  border: none;
  float: right;
  margin-top: -12px;
  color: rgba(255, 255, 255, 0.5);
}

.videos.playlist li .pause {
  color: #8e44ad;
}

.videos.playlist ul {
  max-height: 305px;
}

.head-play-list p {
  color: #fff;
}

.head-play-list {
  background: #1d1d2d;
  padding: 20px;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.rendom {
  color: #fff;
  position: absolute;
  right: 20px;
  top: 30px;
}

.head-play-list span {
  color: #999999;
}

.head-play-list .avatar {
  width: 50px;
  height: 50px;
  display: inline-block;
}

.videos ::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0);
}

.videos ::-webkit-scrollbar-button {
  background: rgba(0, 0, 0, 0);
}

.videos ::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}

.videos ::-webkit-scrollbar-track-piece {
  background: rgba(255, 255, 255, 0);
}

.videos ::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  width: 6px;
}

.videos ::-webkit-scrollbar-corner {
  background: rgba(255, 255, 255, 0);
}

.videos ::-webkit-resizer {
  background: rgba(255, 255, 255, 0);
}

.light-layout .videos ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
}

/**
 * Simple fade transition,
 */
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}
